.body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background: #222;
    font-family: system-ui;
    margin: 2rem;
    background: rgba(53, 59, 83, 0.479) !important;
  }

  .fullContainer {
    width: 100vw;
    height: 100vh;
    padding: 25px;
    outline: dashed 1px black;
    position: relative;
  }

  .icon{
    width: 6rem;
    fill: green;
    // filter: brightness(0) saturate(100%) invert(30%) sepia(54%) saturate(1099%) hue-rotate(326deg) brightness(97%) contrast(92%);
    // filter: brightness(0) saturate(100%) invert(62%) sepia(98%) saturate(421%) hue-rotate(84deg) brightness(98%) contrast(84%);
  }
  
  .keyboard {
    --radius: 4vmin;
    --red: rgb(255, 100, 100);
    --green: rgb(100, 200, 100);
    --blue: rgb(100, 100, 255);
    
    .keyboadrInner {
      display: flex;
      gap: 1vmin;
      padding: 0.75vmin 1vmin 1vmin;
      
      .key {
        width: 15vmin;
        height: 10vmin;
        padding: 0.6vmin 0.6vmin 0.9vmin;
        top: 0.5vmin;
        position: relative;
        overflow: hidden;
        border-radius: calc(var(--radius) * 0.5);
        transform: translate3d(0, 0, 0); // safari fix lol
        animation: shadow 5s -0.5s linear infinite;
        --delay: 0;
  
        @keyframes shadow {
          0% { box-shadow: 0 0 5vmin -2vmin var(--red); }
          33% { box-shadow: 0 0 5vmin -2vmin var(--blue); }
          66% { box-shadow: 0 0 5vmin -2vmin var(--green); }
          100% { box-shadow: 0 0 5vmin -2vmin var(--red); }
        }
  
        &:before {
          content: '';
          width: 400vmin;
          height: 400vmin;
          position: absolute;
          top: 150%;
          left: 150%;
          transform: translate(-50%, -50%);
        //background: conic-gradient(var(--red), var(--green), var(--blue), var(--red));
          animation: spin 5s var(--delay) linear infinite;
  
          @keyframes spin {
            from { transform: translate(-50%, -50%) rotate(0deg); }
            to { transform: translate(-50%, -50%) rotate(360deg); }
          }
        }
  
        .keyContent {
          width: 100%;
          height: 100%;
          background-color: #111;
          position: relative;
          border-radius: calc(var(--radius) * 0.4);
          font-size: 3vmin; 
          top: -0.3vmin;
  
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFF;
          //color: rgb(100, 200, 100);
          z-index: 1;
          cursor: pointer;
          user-select: none;
          box-shadow: 0 0.6vmin rgba(0, 0, 0, 0.6);
          transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out;
          
          &:active {
            top: 0vmin;
            box-shadow: 0 0.3vmin rgba(0, 0, 0, 0.6);
            background-color: rgba(#111, 0.9);
          }
          
          @keyframes color {
            0% { color: var(--red); }
            33% { color: var(--blue); }
            66% { color: var(--green); }
            100% { color: var(--red); }
          }
        }
      }
    }
  }

  $shadow: -0.6rem 0.6rem 0 rgba(29, 30, 28, 0.26);

  .feedback {
    margin: 25px;

    .feedbackCard {
        border-radius: 10px;
        border: 1px solid black;
        max-width: 100%;
        background-color: #fff;
        margin: 0 auto;
        box-shadow: $shadow;
    }
    .feedbackHeader {
        text-align: center;
        padding: 8px;
        font-size: 14px;
        font-weight: 700;
        border-bottom: 1px solid black;
    }
    .feedbackBody {
        padding: 20px;
        display: flex;
        flex-direction: column;
        
        &__message {
            margin-top: 10px;            
        }
    }
}