@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,100);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,500,700,300,100);
$size-cube: 210px;
$faces-interval: ($size-cube/2)/5;
$size-dot: 2px;
$delay-anim: .3s;
body {
  margin: 0;
  padding: 0;
  background: #041234;
  font-family: 'Roboto', sans-serif;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased!important;
  text-rendering: optimizeLegibility!important;
  -moz-osx-font-smoothing: grayscale;
}

.mod {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  perspective: 330px;
  //background: #0033FF;
  background: #041234;
  transform: rotate(270deg);
  
  .project-name {
    position: absolute;
    width: $size-cube*2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.75em;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    text-align: center;
    line-height: 1.5em;
    font-family: 'Roboto Mono';
    font-weight: 300;
  }
  .cube {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -$size-cube/2;
    margin-left: -$size-cube/2;
    width: $size-cube;
    height: $size-cube;
    //-webkit-transform-style: preserve-3d;
    //-webkit-transform-origin: $size-cube/2 $size-cube/2;
    animation: spinCube 9000ms linear infinite;

    -webkit-transform-style: preserve-3d; /* Soporte para WebKit (Chrome, Safari) */
    -moz-transform-style: preserve-3d;    /* Soporte para Firefox */
    -ms-transform-style: preserve-3d;     /* Soporte para Internet Explorer */
    transform-style: preserve-3d;         /* Soporte estándar */

    -webkit-transform-origin: $size-cube/2 $size-cube/2;
    -moz-transform-origin: $size-cube/2 $size-cube/2;
    -ms-transform-origin: $size-cube/2 $size-cube/2;
    transform-origin: $size-cube/2 $size-cube/2;

    .faces {
      position: absolute;
      top: 0px;
      left: 0px;
      width: $size-cube;
      height: $size-cube;
      //animation: spinCube 4500ms linear infinite;
      
      .dot {
        position: absolute;
        background: rgba(255, 255, 255, 1);
        width: $size-dot;
        height: $size-dot;
        border-radius: 50%;
      }
      .p1 {
        top: -$size-dot/2;
        ;
        margin-left: -$size-dot/2;
      }
      .p2 {
        top: -$size-dot/2;
        ;
        left: 50%;
        margin-left: - ($size-dot/2);
      }
      .p3 {
        top: -$size-dot/2;
        ;
        left: 100%;
        margin-left: -$size-dot/2;
      }
      .p4 {
        top: 50%;
        left: -$size-dot/2;
        margin-top: -$size-dot/2;
      }
      .p5 {
        top: 50%;
        left: 50%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p6 {
        top: 50%;
        left: 100%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p7 {
        top: 100%;
        left: 0%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p8 {
        top: 100%;
        left: 50%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p9 {
        top: 100%;
        left: 100%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p10 {
        top: 25%;
        ;
        left: 25%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p11 {
        top: 25%;
        ;
        left: 75%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p12 {
        top: 75%;
        ;
        left: 25%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
      .p13 {
        top: 75%;
        ;
        left: 75%;
        margin-top: -$size-dot/2;
        margin-left: -$size-dot/2;
      }
    }
    .f1 {
      //-webkit-transform: translateZ(-$faces-interval*5);
      animation-delay: $delay-anim;

      -webkit-transform: translateZ(-$faces-interval*5);
      -moz-transform: translateZ(-$faces-interval*5);
      -ms-transform: translateZ(-$faces-interval*5);
      transform: translateZ(-$faces-interval*5);
    }
    .f2 {
      //-webkit-transform: translateZ(-$faces-interval*4);
      animation-delay: $delay-anim*2;

      -webkit-transform: translateZ(-$faces-interval*4);
      -moz-transform: translateZ(-$faces-interval*4);
      -ms-transform: translateZ(-$faces-interval*4);
      transform: translateZ(-$faces-interval*4);
    }
    .f3 {
      //-webkit-transform: translateZ(-$faces-interval*3);
      animation-delay: $delay-anim*3;

      -webkit-transform: translateZ(-$faces-interval*3);
      -moz-transform: translateZ(-$faces-interval*3);
      -ms-transform: translateZ(-$faces-interval*3);
      transform: translateZ(-$faces-interval*3);
    }
    .f4 {
      //-webkit-transform: translateZ(-$faces-interval*2);
      animation-delay: $delay-anim*4;

      -webkit-transform: translateZ(-$faces-interval*2);
      -moz-transform: translateZ(-$faces-interval*2);
      -ms-transform: translateZ(-$faces-interval*2);
      transform: translateZ(-$faces-interval*2);
    }
    .f5 {
      //-webkit-transform: translateZ(-$faces-interval*1);
      animation-delay: $delay-anim*5;

      -webkit-transform: translateZ(-$faces-interval*1);
      -moz-transform: translateZ(-$faces-interval*1);
      -ms-transform: translateZ(-$faces-interval*1);
      transform: translateZ(-$faces-interval*1);
    }
    .f6 {
      //-webkit-transform: translateZ(0px);
      animation-delay: $delay-anim*6;

      -webkit-transform: translateZ(0px);
      -moz-transform: translateZ(0px);
      -ms-transform: translateZ(0px);
      transform: translateZ(0px);
    }
    .f7 {
      //-webkit-transform: translateZ($faces-interval*1);
      animation-delay: $delay-anim*7;

      -webkit-transform: translateZ($faces-interval*1);
      -moz-transform: translateZ($faces-interval*1);
      -ms-transform: translateZ($faces-interval*1);
      transform: translateZ($faces-interval*1);
    }
    .f8 {
      //-webkit-transform: translateZ($faces-interval*2);
      animation-delay: $delay-anim*8;

      -webkit-transform: translateZ($faces-interval*2);
      -moz-transform: translateZ($faces-interval*2);
      -ms-transform: translateZ($faces-interval*2);
      transform: translateZ($faces-interval*2);
    }
    .f9 {
      //-webkit-transform: translateZ($faces-interval*3);
      animation-delay: $delay-anim*9;

      -webkit-transform: translateZ($faces-interval*3);
      -moz-transform: translateZ($faces-interval*3);
      -ms-transform: translateZ($faces-interval*3);
      transform: translateZ($faces-interval*3);
    }
    .f10 {
      //-webkit-transform: translateZ($faces-interval*4);
      animation-delay: $delay-anim*10;

      -webkit-transform: translateZ($faces-interval*4);
      -moz-transform: translateZ($faces-interval*4);
      -ms-transform: translateZ($faces-interval*4);
      transform: translateZ($faces-interval*4);
    }
    .f11 {
      //-webkit-transform: translateZ($faces-interval*5);
      animation-delay: $delay-anim*11;

      -webkit-transform: translateZ($faces-interval*5);
      -moz-transform: translateZ($faces-interval*5);
      -ms-transform: translateZ($faces-interval*5);
      transform: translateZ($faces-interval*5);
    }
  }
}

@-webkit-keyframes spinCube {
  0% {
    -webkit-transform: rotateY(0deg) rotateX(-45deg) rotate(0deg);
            transform: rotateY(0deg) rotateX(-45deg) rotate(0deg);
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(135deg) rotate(180deg);
            transform: rotateY(180deg) rotateX(135deg) rotate(180deg);
  }
  100% {
    -webkit-transform: rotateY(360deg) rotateX(315deg) rotate(360deg);
            transform: rotateY(360deg) rotateX(315deg) rotate(360deg);
  }
}

@keyframes spinCube {
  0% {
    -webkit-transform: rotateY(0deg) rotateX(-45deg) rotate(0deg);
       -moz-transform: rotateY(0deg) rotateX(-45deg) rotate(0deg);
        -ms-transform: rotateY(0deg) rotateX(-45deg) rotate(0deg);
         -o-transform: rotateY(0deg) rotateX(-45deg) rotate(0deg);
            transform: rotateY(0deg) rotateX(-45deg) rotate(0deg);
  }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(135deg) rotate(180deg);
       -moz-transform: rotateY(180deg) rotateX(135deg) rotate(180deg);
        -ms-transform: rotateY(180deg) rotateX(135deg) rotate(180deg);
         -o-transform: rotateY(180deg) rotateX(135deg) rotate(180deg);
            transform: rotateY(180deg) rotateX(135deg) rotate(180deg);
  }
  100% {
    -webkit-transform: rotateY(360deg) rotateX(315deg) rotate(360deg);
       -moz-transform: rotateY(360deg) rotateX(315deg) rotate(360deg);
        -ms-transform: rotateY(360deg) rotateX(315deg) rotate(360deg);
         -o-transform: rotateY(360deg) rotateX(315deg) rotate(360deg);
            transform: rotateY(360deg) rotateX(315deg) rotate(360deg);
  }
}

@-webkit-keyframes spinDots {
  0% {
    -webkit-transform: rotateX(0deg) rotateY(45deg);
            transform: rotateX(0deg) rotateY(45deg);
  }
  50% {
    -webkit-transform: rotateX(-180deg) rotateY(-135deg);
            transform: rotateX(-180deg) rotateY(-135deg);
  }
  100% {
    -webkit-transform: rotateX(-360deg) rotateY(-315deg);
            transform: rotateX(-360deg) rotateY(-315deg);
  }
}

@keyframes spinDots {
  0% {
    -webkit-transform: rotateX(0deg) rotateY(45deg);
       -moz-transform: rotateX(0deg) rotateY(45deg);
        -ms-transform: rotateX(0deg) rotateY(45deg);
         -o-transform: rotateX(0deg) rotateY(45deg);
            transform: rotateX(0deg) rotateY(45deg);
  }
  50% {
    -webkit-transform: rotateX(-180deg) rotateY(-135deg);
       -moz-transform: rotateX(-180deg) rotateY(-135deg);
        -ms-transform: rotateX(-180deg) rotateY(-135deg);
         -o-transform: rotateX(-180deg) rotateY(-135deg);
            transform: rotateX(-180deg) rotateY(-135deg);
  }
  100% {
    -webkit-transform: rotateX(-360deg) rotateY(-315deg);
       -moz-transform: rotateX(-360deg) rotateY(-315deg);
        -ms-transform: rotateX(-360deg) rotateY(-315deg);
         -o-transform: rotateX(-360deg) rotateY(-315deg);
            transform: rotateX(-360deg) rotateY(-315deg);
  }
}

@-webkit-keyframes spinFaces {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  50% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

@keyframes spinFaces {
  0% {
    -webkit-transform: rotateX(0deg);
       -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
         -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  50% {
    -webkit-transform: rotateX(180deg);
       -moz-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
         -o-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(0deg);
       -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
         -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
}

form.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
